import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import LocalizedLink from '../components/LocalizedLink'
import useTranslations from '../components/useTranslations'
import * as S from '../components/SensorsList/styled'
import DocumentDownload from '../components/LandingPage/documentDownload'

const SensorsList = ({ data, pageContext: { locale } }) => {
  const { retmonTitle, lang, navbar } = useTranslations()
  const SensorsListTR = data.TR.edges
  const SensorsListEN = data.EN.edges

  return (
    <div className="homepage">
      <SEO
        title={`${navbar.productsSub.sensorsEquipments} | ${retmonTitle}`}
        description=""
        lang={lang}
      />
      <S.Container>
          <S.Title>{navbar.productsSub.sensorsEquipments}</S.Title>
        <S.RowSectionStart justify="space-between" className='grid-list'>
          {locale === 'en'
            ? SensorsListEN.map(
              ({
                node: {
                  frontmatter: {
                    subTitle,
                    title,
                    featuredImage,
                    subURL,
                    cloudinaryURL,
                    status
                  },
                  fields: { slug },
                },
              }) => <>
                  {status === false ?
                    // <S.BorderedColumn className='grid-item status-disable'>
                    //   <S.ColumnItems auto>
                    //     {cloudinaryURL ? (
                    //       <S.FeaturedCloudImage
                    //         src={cloudinaryURL}
                    //         alt={title}
                    //       />
                    //     ) : (
                    //         <S.FeaturedImage
                    //           fluid={featuredImage.childImageSharp.fluid}
                    //           alt={title}
                    //         />
                    //       )}
                    //   </S.ColumnItems>
                    //   <S.ColumnText height>
                    //     <S.SubHeader bold>{title}</S.SubHeader>
                    //     <S.SubHeader>{subTitle}</S.SubHeader>
                    //     <span className='status-disable-span'>This product is not available.</span>
                    //   </S.ColumnText>
                    // </S.BorderedColumn>
                    null
                    :
                    <LocalizedLink to={`/${subURL}/${slug}`}>
                      <S.BorderedColumn className='grid-item'>
                        <S.ColumnItems auto>
                          {cloudinaryURL ? (
                            <S.FeaturedCloudImage
                              src={cloudinaryURL}
                              alt={title}
                            />
                          ) : (
                              <S.FeaturedImage
                                fluid={featuredImage.childImageSharp.fluid}
                                alt={title}
                              />
                            )}
                        </S.ColumnItems>
                        <S.ColumnItems height>
                          <S.SubHeader bold>{title}</S.SubHeader>
                          <S.SubHeader>{subTitle}</S.SubHeader>
                        </S.ColumnItems>
                      </S.BorderedColumn>
                    </LocalizedLink>
                  }
                </>
            )
            : SensorsListTR.map(
              ({
                node: {
                  frontmatter: { subTitle, title, featuredImage, subURL, cloudinaryURL, status },
                  fields: { slug },
                },
              }) => (
                  <>
                    {status === false ?
                      null
                      :
                      <LocalizedLink to={`/${subURL}/${slug}`}>
                        <S.BorderedColumn className='grid-item'>
                          <S.ColumnItems auto>
                            {cloudinaryURL ? (
                              <S.FeaturedCloudImage
                                src={cloudinaryURL}
                                alt={title}
                              />
                            ) : (
                                <S.FeaturedImage
                                  fluid={featuredImage.childImageSharp.fluid}
                                  alt={title}
                                />
                              )}
                          </S.ColumnItems>
                          <S.ColumnText height>
                            <S.SubHeader bold>{title}</S.SubHeader>
                            <S.SubHeader>{subTitle}</S.SubHeader>
                          </S.ColumnText>
                        </S.BorderedColumn>
                      </LocalizedLink>
                    }
                  </>
                )
            )}
        </S.RowSectionStart>
        <S.DividerForRow />
      </S.Container>
    </div >
  )
}

export const query = graphql`
  query SensorsEquipmentsList {
    EN: allMarkdownRemark(
      filter: {
        frontmatter: { page: { eq: "SensorsEquipments" } }
        fields: { locale: { eq: "en" } }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 250)
          fields {
            slug
            locale
          }
          frontmatter {
            page
            status
            subTitle
            title
            subURL
            cloudinaryURL
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
    TR: allMarkdownRemark(
      filter: {
        frontmatter: { page: { eq: "SensorsEquipments" } }
        fields: { locale: { eq: "tr" } }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          html
          excerpt(pruneLength: 250)
          fields {
            slug
            locale
          }
          frontmatter {
            page
            status
            subURL
            subTitle
            title
            cloudinaryURL
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SensorsList
